import React, { useState } from 'react';
import { bool, func, node, number, string, object, shape } from 'prop-types';
import { createResourceLocatorString } from '../../../util/routes';
import { useHistory } from 'react-router-dom';
import classNames from 'classnames';
import { useRouteConfiguration } from '../../../context/routeConfigurationContext';
import { FormattedMessage, useIntl, intlShape } from '../../../util/reactIntl';
import { propTypes } from '../../../util/types';
import css from './SearchFiltersDesktop.module.css';
import PopupOpenerButton from '../PopupOpenerButton/PopupOpenerButton';
import { Modal, Button } from '../../../components';

const SearchFiltersDesktopComponent = props => {
  const {
    rootClassName,
    className,

    showAsModalMaxWidth,
    onManageDisableScrolling,
    selectedFiltersCount,
    intl,
    isMapVariant,
    resultsCount,
    children,
  } = props;

  const [isFiltersOpenOnDesktop, setIsFiltersOpenOnDesktop] = useState(false);
  const [initialQueryParams, setInitialQueryParams] = useState(null);
  const history = useHistory();
  // Open filters modal, set the initial parameters to current ones
  const openFilters = () => {
    const { onOpenModal, urlQueryParams } = props;
    onOpenModal();
    setIsFiltersOpenOnDesktop(true);
    setInitialQueryParams(urlQueryParams);
  };
  // Close the filters by clicking cancel, revert to the initial params
  const cancelFilters = () => {
    const {
      history,
      onCloseModal,
      routeConfiguration,
      currentPageName,
      params,
    } = props;

    history.push(
      createResourceLocatorString(
        currentPageName,
        routeConfiguration,
        params,
        initialQueryParams
      )
    );
    onCloseModal();
    setIsFiltersOpenOnDesktop(false);
    setInitialQueryParams(null);
  };

  // Close the filter modal
  const closeFilters = () => {
    props.onCloseModal();
    setIsFiltersOpenOnDesktop(false);
  };

  // Reset all filter query parameters
  const resetAll = e => {
    props.resetAll(e);

    // blur event target if event is passed
    if (e && e.currentTarget) {
      e.currentTarget.blur();
    }
  };
  const classes = classNames(rootClassName || css.root, className);
  const resultsFound = (
    <FormattedMessage
      id="SearchFiltersMobile.foundResults"
      values={{ count: resultsCount }}
    />
  );
  const noResults = <FormattedMessage id="SearchFiltersMobile.noResults" />;
  const loadingResults = (
    <FormattedMessage id="SearchFiltersMobile.loadingResults" />
  );
  const filtersHeading = intl.formatMessage({
    id: 'SearchFiltersMobile.heading',
  });
  const modalCloseButtonMessage = intl.formatMessage({
    id: 'SearchFiltersMobile.cancel',
  });

  const showListingsLabel = intl.formatMessage(
    { id: 'SearchFiltersMobile.showListings' },
    { count: resultsCount }
  );
  return (
    <div>
      <div className={css.buttons}>
        {/*<PopupOpenerButton
          isSelected={selectedFiltersCount > 0}
          toggleOpen={openFilters}
        >
          <FormattedMessage
            id="SearchFiltersMobile.filtersButtonLabel"
            className={css.mapIconText}
          />
        </PopupOpenerButton>*/}
        <span
          className="list-filter button button-small button-white button-animate button-animate-offwhite fixedwidth"
          onClick={() => openFilters()}
        >
          <span className="icon material-symbols-outlined">sort</span>
          Filter
        </span>
      </div>
      <Modal
        id="SearchFiltersMobile.filters"
        isOpen={isFiltersOpenOnDesktop}
        onClose={cancelFilters}
        onManageDisableScrolling={onManageDisableScrolling}
        containerClassName={css.modalContainer}
        closeButtonMessage={modalCloseButtonMessage}
      >
        <div className={css.modalHeadingWrapper}>
          <span className={css.modalHeading}>{filtersHeading}</span>
          <button className={css.resetAllButton} onClick={e => resetAll(e)}>
            <FormattedMessage id={'SearchFiltersMobile.resetAll'} />
          </button>
        </div>
        {isFiltersOpenOnDesktop ? (
          <div className={css.filtersWrapper}>{children}</div>
        ) : null}
        <div className={css.showListingsContainer}>
          <Button className={`${css.showListingsButton} button button-highlight1 button-animate button-animate-dark`} onClick={closeFilters}>
            {showListingsLabel}
          </Button>
        </div>
      </Modal>
    </div>
  );
};

SearchFiltersDesktopComponent.propTypes = {
  urlQueryParams: object.isRequired,
  showAsModalMaxWidth: number.isRequired,
  onManageDisableScrolling: func.isRequired,
  onOpenModal: func.isRequired,
  onCloseModal: func.isRequired,
  resetAll: func.isRequired,
  selectedFiltersCount: number,
  isMapVariant: bool,
  resultsCount: number,
  // from useHistory
  history: shape({
    push: func.isRequired,
  }).isRequired,
};

const SearchFiltersDesktop = props => {
  const routeConfiguration = useRouteConfiguration();
  const intl = useIntl();
  const history = useHistory();

  return (
    <SearchFiltersDesktopComponent
      routeConfiguration={routeConfiguration}
      intl={intl}
      history={history}
      {...props}
    />
  );
};

export default SearchFiltersDesktop;
