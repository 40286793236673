import classNames from 'classnames';
import { object, string } from 'prop-types';
import React from 'react';

import { FormattedMessage } from '../../util/reactIntl';
import NoImageIcon from '../ResponsiveImage/NoImageIcon';

import css from './ResponsiveImageAirtable.module.css';

const ResponsiveImageAirtable = props => {
  const {
    className,
    rootClassName,
    alt,
    noImageMessage,
    image,
    ...rest
  } = props;
  const classes = classNames(rootClassName || css.root, className);

  if (image === null) {
    const noImageClasses = classNames(
      rootClassName || css.root,
      css.noImageContainer,
      className,
    );

    const noImageMessageText = noImageMessage || (
      <FormattedMessage id="ResponsiveImageAirtable.noImage" />
    );
    return (
      <div className={noImageClasses}>
        <div className={css.noImageWrapper}>
          <NoImageIcon className={css.noImageIcon} />
          <div className={css.noImageText}>{noImageMessageText}</div>
        </div>
      </div>
    );
  }

  const imageVariants = ['full', 'small', 'large'];

  // Flex image object or airtable image
  const thumbnails = image?.thumbnails;

  const srcSet = imageVariants
    .map(variantName => {
      const variant = thumbnails[variantName];

      if (!variant) {
        // Variant not available (most like just not loaded yet)
        return null;
      }
      return `${variant.url} ${variant.width}w`;
    })
    .filter(v => v != null)
    .join(', ');

  const imgProps = {
    className: classes,
    srcSet,
    ...rest,
  };

  return <img alt={alt} {...imgProps} />;
};

ResponsiveImageAirtable.defaultProps = {
  className: null,
  rootClassName: null,
  image: null,
  sizes: null,
  noImageMessage: null,
};

ResponsiveImageAirtable.propTypes = {
  className: string,
  rootClassName: string,
  alt: string.isRequired,
  image: object,
  sizes: string,
  noImageMessage: string,
};

export default ResponsiveImageAirtable;
