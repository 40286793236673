import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import isEmpty from 'lodash/isEmpty';
import useDragScroll from '../../../hooks/useDragScroll';
import { FormattedText } from '../../../util/data';
import ResponsiveImageAirtable from '../../../components/ResponsiveImageAirtable/ResponsiveImageAirtable';
import { NamedLink, IconSpinner } from '../../../components';

import css from './CategorySection.module.css';

const getCategoryLabel = (categoriesList, url_key) => {
  const categoryItem = categoriesList?.find(item => item.url_key === url_key);
  return categoryItem ? categoryItem.label : '';
};

const getTitleAndDescription = (categoriesList, url_key) => {
  const categoryItem = categoriesList?.find(item => item.url_key === url_key);
  return categoryItem
    ? {
        page_title: categoryItem.page_title,
        page_description: categoryItem.page_description,
        page_h1_title: categoryItem.page_h1_title,
      }
    : {};
};

const CategorySection = props => {
  const { categoriesList, urlParams } = props;

  // If the user is not on any Category Page we don't render this component
  if (isEmpty(urlParams)) return null;

  const containerRef = useRef(null);
  useDragScroll(containerRef);

  const { fetchingSectionData, fetchingProductCategories } = useSelector(
    state => state.SearchPage
  );
  if (fetchingSectionData || fetchingProductCategories)
    return (
      <div className={css.loadingWrapper}>
        <IconSpinner />
      </div>
    );
  const { productType, topLevelCategory, parentCategory } = urlParams;

  const categoriesToDisplay = {
    [!!topLevelCategory]: {
      name: getCategoryLabel(categoriesList.topLevelCategory, topLevelCategory),
      parent: topLevelCategory,
      categoriesList: categoriesList.parentCategory?.filter(
        item => item.parent === topLevelCategory
      ),
      pageName: 'CategoryPageWithParent',
      linkProps: {
        topLevelCategory,
      },
      missingSlug: 'parentCategory',
      ...getTitleAndDescription(
        categoriesList.topLevelCategory,
        topLevelCategory
      ),
    },
    [!!parentCategory]: {
      name: getCategoryLabel(categoriesList.parentCategory, parentCategory),
      parent: parentCategory,
      categoriesList: categoriesList.productType?.filter(
        item => item.parent === parentCategory
      ),
      pageName: 'CategoryPageWithProductType',
      linkProps: {
        topLevelCategory,
        parentCategory,
      },
      missingSlug: 'productType',
      ...getTitleAndDescription(categoriesList.parentCategory, parentCategory),
    },
    [!!productType]: {
      name: getCategoryLabel(categoriesList.productType, productType),
      parent: productType,
      categoriesList: [],
      ...getTitleAndDescription(categoriesList.productType, productType),
    },
  };
  const relatedCategories = categoriesToDisplay[true];

  // return (
  //   <div className={css.container}>
  //     <h1 className={css.title}>{relatedCategories?.name}</h1>
  //     {!isEmpty(relatedCategories?.categoriesList) && (
  //       <>
  //         <FormattedText
  //           id="CategorySection.title"
  //           tagName="p"
  //           className={css.title}
  //         />
  //         <div className={css.contentContainer}>
  //           {relatedCategories?.categoriesList?.map(item => {
  //             return (
  //               item.parent === relatedCategories.parent && (
  //                 <NamedLink
  //                   key={item.key}
  //                   className={css.cardImg}
  //                   name={relatedCategories.pageName}
  //                   params={{
  //                     ...relatedCategories.linkProps,
  //                     [relatedCategories.missingSlug]: item.key,
  //                   }}
  //                 >
  //                   <ResponsiveImageAirtable
  //                     alt={item.label}
  //                     image={item.img?.[0]}
  //                     thumbnail={item.img?.[0].thumbnails?.small}
  //                     rootClassName={css.img}
  //                   />
  //                   <p>{item.label}</p>
  //                 </NamedLink>
  //               )
  //             );
  //           })}
  //         </div>
  //       </>
  //     )}
  //   </div>
  // );

  // JH Note: Right now we are showing the title and description
  // of every category page, no matter their depth.
  // if (isEmpty(relatedCategories?.categoriesList)) {
  //   return null;
  // }

  return (
    <>
      <div id={css['category-description']}>
        <div className={`${css.container} ${css.text}`}>
          <h1>{relatedCategories?.page_h1_title}</h1>
          <p>{relatedCategories?.page_description}</p>
        </div>
      </div>
      {/*!isEmpty(relatedCategories?.categoriesList) && (
        <div id={css['category-featured-cats']}>
          <div className={`${css.container} ${css.full}`}>
            <div className="categoryView layout-type-featured">
              <div className="list scroll has-scroll" ref={containerRef}>
                {relatedCategories?.categoriesList?.map((item, index) => {
                  return (
                    item.parent === relatedCategories.parent && (
                      <div className={`${css.catplate} box`} key={index}>
                        <NamedLink
                          key={item.key}
                          className={css.cardImg}
                          name={relatedCategories.pageName}
                          params={{
                            ...relatedCategories.linkProps,
                            [relatedCategories.missingSlug]: item.key,
                          }}
                        >
                          <span className={`${css.image} image hover-large`}>
                            <ResponsiveImageAirtable
                              alt={item.label}
                              image={item.img?.[0]}
                              thumbnail={item.img?.[0].thumbnails?.small}
                              className={css.catimg}
                            />
                          </span>
                          <p className="catname">
                            {item.label}
                            <span className="icon material-symbols-outlined">
                              arrow_right_alt
                            </span>
                          </p>
                        </NamedLink>
                      </div>
                    )
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      )*/}
    </>
  );
};

export default CategorySection;
