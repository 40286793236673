import React from 'react';
import { bool, func, node, number, string, object } from 'prop-types';
import classNames from 'classnames';

import { FormattedMessage } from '../../../util/reactIntl';
import SearchFiltersDesktop from '../SearchFiltersDesktop/SearchFiltersDesktop';
import css from './MainPanelHeader.module.css';

const MainPanelHeader = props => {
  const {
    rootClassName,
    className,
    children,
    filterComponent,
    sortByComponent,
    isSortByActive,
    listingsAreLoaded,
    resultsCount,
    searchInProgress,
    noResultsInfo,
    urlQueryParams,
    showAsModalMaxWidth,
    onManageDisableScrolling,
    onOpenModal,
    onCloseModal,
    resetAll,
    selectedFiltersCount,
    isMapVariant,
    currentPageName,
    params,
  } = props;

  const classes = classNames(rootClassName || css.root, className);

  return (
    <div className={classes}>
      <div className={`${css.searchOptions} ${css['list-navigate-top']}`}>
        <div className={css.searchResultSummary}>
          <span className={css.resultsFound}>
            {searchInProgress ? (
              <FormattedMessage id="MainPanelHeader.loadingResults" />
            ) : (
              <FormattedMessage
                id="MainPanelHeader.foundResults"
                values={{ count: resultsCount }}
              />
            )}
          </span>
        </div>
        <div className={css.filterByWrapper}>
          <SearchFiltersDesktop
            urlQueryParams={urlQueryParams}
            showAsModalMaxWidth={showAsModalMaxWidth}
            onManageDisableScrolling={onManageDisableScrolling}
            onOpenModal={onOpenModal}
            onCloseModal={onCloseModal}
            resetAll={resetAll}
            selectedFiltersCount={selectedFiltersCount}
            isMapVariant={isMapVariant}
            resultsCount={resultsCount}
            currentPageName={currentPageName}
            params={params}
          >
            {filterComponent}
          </SearchFiltersDesktop>
        </div>

        {isSortByActive ? (
          <div className={css.sortyByWrapper}>
            {/*<span className={css.sortyBy}>
              <FormattedMessage id="MainPanelHeader.sortBy" />
            </span>*/}

            {sortByComponent}
          </div>
        ) : null}
      </div>

      {children}

      {noResultsInfo ? noResultsInfo : null}
    </div>
  );
};

MainPanelHeader.defaultProps = {
  rootClassName: null,
  className: null,
  resultsCount: null,
  searchInProgress: false,
  sortByComponent: null,
};

MainPanelHeader.propTypes = {
  urlQueryParams: object.isRequired,
  showAsModalMaxWidth: number.isRequired,
  onManageDisableScrolling: func.isRequired,
  onOpenModal: func.isRequired,
  onCloseModal: func.isRequired,
  resetAll: func.isRequired,
  selectedFiltersCount: number,
  isMapVariant: bool,

  rootClassName: string,
  className: string,
  listingsAreLoaded: bool.isRequired,
  resultsCount: number,
  searchInProgress: bool,
  sortByComponent: node,
};

export default MainPanelHeader;
