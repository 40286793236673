import React from 'react';
import { FormattedMessage } from '../../../util/reactIntl';
import { NamedLink } from '../../../components';

import css from './NoSearchResultsMaybe.module.css';

const NoSearchResultsMaybe = props => {
  const { listingsAreLoaded, totalItems, location, resetAll } = props;
  const hasNoResult = listingsAreLoaded && totalItems === 0;
  const hasSearchParams = location.search?.length > 0;
  return hasNoResult ? (
    <div className={css.noSearchResults}>      
      <FormattedMessage id="SearchPage.noResults" />

      {/*{hasSearchParams ? (
        <p className={css.resetfilterlink}>
          <button
            className={css.resetAllFiltersButton}
            onClick={e => resetAll(e)}
          >
            <FormattedMessage id={'SearchPage.resetAllFilters'} />
          </button>
        </p>
      ) : null}*/}
    </div>
  ) : null;
};

export default NoSearchResultsMaybe;
