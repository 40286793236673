import React from 'react';

import { FormattedMessage } from '../../../util/reactIntl';

import css from './SearchHeader.module.css';

const SearchHeader = props => {
  const { searchParams } = props;

  const { keywords } = searchParams;

  return (
    <>
      <div id={css['search-top-label']}>
        <div className={`${css.searchContainer} ${css.text}`}>
          <p>
            <FormattedMessage id="SearchPage.searchToplabel" />
          </p>
          <h1>{keywords}</h1>          
        </div>
      </div>
    </>
  );
};

export default SearchHeader;
